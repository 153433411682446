import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../config/query-keys'
import api from '../../api'
import { useToast } from '../../providers/ToastProvider'

export default function usePetQuery({ _id }: { _id: string }) {
  const { showToast } = useToast()

  const query = useQuery({
    queryKey: QUERY_KEYS.PET(_id),
    queryFn: async () => {
      const data = await api.pet.getPetById({
        _id,
      })

      return data.pet
    },
    throwOnError: (error: any) => {
      showToast({
        type: 'error',
        message: error.response.data.message,
        code: error.response.data.code,
      })
      throw Error(error.response.data.message)
    },
  })

  return {
    ...query,
  }
}
