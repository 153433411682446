import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Redirect from './views/redirect'
import { ROUTES } from './config/routes'
import LoginPage from './views/login/LoginPage'
import CreatePetPage from './views/pet/CreatePetPage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ToastProvider from './providers/ToastProvider'
import MyPetsPage from './views/pet/MyPetsPage'
import RegisterPage from './views/login/RegisterPage'
import MyPetPage from './views/pet/MyPetPage'

function App() {
  const queryClient = new QueryClient()

  return (
    <ToastProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path={ROUTES.HOME()} element={<LoginPage />} />
            <Route path={ROUTES.REGISTER()} element={<RegisterPage />} />
            <Route path={ROUTES.MY_PET_GROUP()} element={<MyPetsPage />} />
            <Route path={ROUTES.CREATE_PET()} element={<CreatePetPage />} />
            <Route path={ROUTES.MY_PET_BY_ID()} element={<MyPetPage />} />
            <Route path="*" element={<Redirect path={ROUTES.HOME()} />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ToastProvider>
  )
}

export default App
