import { useForm } from 'react-hook-form'
import api from '../../api'
import { useToast } from '../../providers/ToastProvider'

export default function useCreatePetForm() {
  const { showToast } = useToast()

  const form = useForm<{
    name: string
  }>()

  const submit = form.handleSubmit(async (values) => {
    try {
      const pet = await api.pet.createPet(values)

      return pet
    } catch (error: any) {
      showToast({
        type: 'error',
        message: error.response.data.message,
        code: error.response.data.code,
      })
      throw new Error(error.response.data.message)
    }
  })

  return {
    ...form,
    submit,
  }
}
