import ArrowLeftCircleIcon from '@heroicons/react/24/outline/ArrowLeftCircleIcon'
import { Link } from 'react-router-dom'
import { ROUTES } from '../config/routes'
import Text from './ui/Text/Text'

function Navbar({
  back = ROUTES.HOME(),
  title = '',
}: {
  back?: string
  title?: string
}) {
  return (
    <nav className="grid grid-cols-3 items-center bg-white rounded-b-2xl">
      <Link to={back}>
        <div className="p-4">
          <ArrowLeftCircleIcon className="h-6 w-6" />
        </div>
      </Link>
      <Text className="text-center" variant="titleMD">
        {title}
      </Text>
    </nav>
  )
}

export default Navbar
