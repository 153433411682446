export type PetAttributes = {
  energy: number
  social: number
  intelligence: number
  luck: number
  patience: number
}

export type PetStates = {
  hunger: number
  happiness: number
  stamina: number
}

export enum PetStatus {
  SLEEPING = 'SLEEPING',
  IDLE = 'IDLE',
}

export enum PetRelationshipTargetType {
  PET = 'PET',
  USER = 'USER',
}

export type PetRelationship = {
  target: string
  targetType: PetRelationshipTargetType
  intimacy: number
  lastInteractionAt: Date
}

export enum PetMood {
  ANNOYED = 'ANNOYED',
  HAPPY = 'HAPPY',
  SAD = 'SAD',
  EATING = 'EATING',
  OVERFED = 'OVERFED',
  SLEEPING = 'SLEEPING',
  IDLE = 'IDLE',
}

export interface Pet {
  _id: string

  name: string
  owner: string

  attributes: PetAttributes
  states: PetStates
  status: PetStatus
  mood: PetMood

  relationships: PetRelationship[]

  details: {
    [key: string]: any
  }
  adminDetails: {
    [key: string]: any
  }

  createdAt: Date
  updatedAt: Date
}
