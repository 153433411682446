export enum ItemType {
  FOOD = 'FOOD',
}

export interface Item {
  _id: string

  name: string
  description: string

  details: {
    [key: string]: any
  }
  adminDetails: {
    [key: string]: any
  }

  createdAt: Date
  updatedAt: Date
}
