import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

function ProgressBar({
  progress,
  color = 'black',
  background = '#F1F5F9',
  className,
  ...props
}: {
  progress: number
  color?: string
  background?: string
  className?: string
}) {
  const [progressBarWidth, setProgressBarWidth] = useState(0)

  return (
    <div
      className={twMerge('rounded-full overflow-hidden flex h-4', className)}
      ref={(e) => {
        if (!e) return
        setProgressBarWidth((e.offsetWidth * progress) / 100)
      }}
      {...props}
    >
      <div
        className="h-4"
        style={{ width: progressBarWidth, backgroundColor: color }}
      />
      <div className="h-4 grow" style={{ backgroundColor: background }} />
    </div>
  )
}

export default ProgressBar
