import Button from '../../components/ui/Button/Button'
import Input from '../../components/ui/Input/Input'
import Text from '../../components/ui/Text/Text'
import useCreatePetForm from '../../hooks/forms/useCreatePetForm'

function CreatePetPage() {
  const { register, submit } = useCreatePetForm()

  return (
    <div className="mx-auto max-w-lg h-screen shadow-lg p-4">
      <h1>Create Pet Page</h1>
      <form onSubmit={submit}>
        <Text>Enter the name of your pet</Text>
        <Input
          type="text"
          placeholder="Name"
          {...register('name', {
            required: 'Name is required',
          })}
        />
        <Button type="submit">Create Pet</Button>
      </form>
    </div>
  )
}

export default CreatePetPage
