import axios from 'axios'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export async function loginInternalUser(data: {
  email: string
  password: string
}) {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/auth/login-internal-user`,
      data,
      {
        withCredentials: true,
      },
    )

    return response.data
  } catch (error: any) {
    throw error
  }
}

export async function registerInternalUser(data: {
  email: string
  password: string
}) {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/auth/register-internal-user`,
      data,
      {
        withCredentials: true,
      },
    )

    return response.data
  } catch (error: any) {
    throw error
  }
}

export async function logout() {
  try {
    await axios.post(
      `${REACT_APP_API_URL}/auth/logout`,
      {},
      { withCredentials: true },
    )
  } catch (error: any) {
    throw error
  }
}
