import { useParams } from 'react-router-dom'
import Text from '../../components/ui/Text/Text'
import usePetQuery from '../../hooks/queries/usePetQuery'
import Navbar from '../../components/Navbar'
import Button from '../../components/ui/Button/Button'
import ProgressBar from '../../components/ProgressBar'
import { useCallback, useState } from 'react'
import {
  PetAttributes,
  PetMood,
  PetRelationship,
  PetStates,
} from '../../entities/pet'
import Show from '../../components/utils/Show'
import api from '../../api'
import { config } from '../../config/config'
import { ItemType } from '../../entities/item'

enum Tabs {
  NONE = 'none',
  STATS = 'stats',
  FEED = 'feed',
  PLAY = 'play',
}

function PetImage({
  name,
  mood,
  reaction,
}: {
  name: string
  mood: PetMood
  reaction?: PetMood
}) {
  const moodToImageUrl = {
    [PetMood.IDLE]: '/pets/PET_IDLE.png',
    [PetMood.SLEEPING]: '/pets/PET_SLEEPING.png',
    [PetMood.EATING]: '/pets/PET_EATING.png',
    [PetMood.HAPPY]: '/pets/PET_HAPPY.png',
    [PetMood.SAD]: '/pets/PET_SAD.png',
    [PetMood.ANNOYED]: '/pets/PET_ANNOYED.png',
    [PetMood.OVERFED]: '/pets/PET_OVERFED.png',
  }

  const moodToText = {
    [PetMood.IDLE]: `${name} is now idle!`,
    [PetMood.SLEEPING]: `${name} is sleeping, zZzZ...`,
    [PetMood.EATING]: `${name} is eating!`,
    [PetMood.HAPPY]: `${name} is happy!`,
    [PetMood.SAD]: `${name} is sad...`,
    [PetMood.ANNOYED]: `${name} is annoyed...`,
    [PetMood.OVERFED]: `${name} is overfed!!`,
  }

  if (reaction) {
    return (
      <div>
        <img src={moodToImageUrl[reaction]} alt="" />
        <Text className="p-4">{moodToText[reaction]}</Text>
      </div>
    )
  }

  return (
    <div>
      <img src={moodToImageUrl[mood]} alt="" />
      <Text className="p-4">{moodToText[mood]}</Text>
    </div>
  )
}

function PetStatesView({ states }: { states: PetStates }) {
  return (
    <>
      <div className="grid grid-cols-12 items-center gap-2">
        <Text className="col-span-3">Happiness</Text>
        <Text className="text-right">{states.happiness.toFixed(0)}</Text>
        <ProgressBar
          className="col-span-8"
          progress={states.happiness}
          color="#448AFF"
        />
      </div>
      <div className="grid grid-cols-12 items-center gap-2">
        <Text className="col-span-3">Hunger</Text>
        <Text className="text-right">{states.hunger.toFixed(0)}</Text>
        <ProgressBar
          className="col-span-8"
          progress={states.hunger}
          color="#448AFF"
        />
      </div>
      <div className="grid grid-cols-12 items-center gap-2">
        <Text className="col-span-3">Stamina</Text>
        <Text className="text-right">{states.stamina.toFixed(0)}</Text>
        <ProgressBar
          className="col-span-8"
          progress={states.stamina}
          color="#448AFF"
        />
      </div>
    </>
  )
}

function PetAttributesView({
  attributes,
  relationship,
}: {
  attributes: PetAttributes
  relationship?: PetRelationship
}) {
  return (
    <>
      <div className="grid grid-cols-12 items-center gap-2">
        <Text className="col-span-3">Energy</Text>
        <Text className="text-right">{attributes.energy.toFixed(0)}</Text>
        <ProgressBar
          className="col-span-8"
          progress={attributes.energy}
          color="#448AFF"
        />
      </div>
      <div className="grid grid-cols-12 items-center gap-2">
        <Text className="col-span-3">Social</Text>
        <Text className="text-right">{attributes.social.toFixed(0)}</Text>
        <ProgressBar
          className="col-span-8"
          progress={attributes.social}
          color="#448AFF"
        />
      </div>
      <div className="grid grid-cols-12 items-center gap-2">
        <Text className="col-span-3">Intelligence</Text>
        <Text className="text-right">{attributes.intelligence.toFixed(0)}</Text>
        <ProgressBar
          className="col-span-8"
          progress={attributes.intelligence}
          color="#448AFF"
        />
      </div>
      <div className="grid grid-cols-12 items-center gap-2">
        <Text className="col-span-3">Luck</Text>
        <Text className="text-right">{attributes.luck.toFixed(0)}</Text>
        <ProgressBar
          className="col-span-8"
          progress={attributes.luck}
          color="#448AFF"
        />
      </div>
      <div className="grid grid-cols-12 items-center gap-2">
        <Text className="col-span-3">Patience</Text>
        <Text className="text-right">{attributes.patience.toFixed(0)}</Text>
        <ProgressBar
          className="col-span-8"
          progress={attributes.patience}
          color="#448AFF"
        />
      </div>
      {relationship && (
        <div className="grid grid-cols-12 items-center gap-2 pt-2">
          <Text className="col-span-3">Intimacy</Text>
          <Text className="text-right">{relationship.intimacy.toFixed(0)}</Text>
          <ProgressBar
            className="col-span-8"
            progress={relationship.intimacy / 10}
            color="#448AFF"
          />
        </div>
      )}
    </>
  )
}

function MyPetPage() {
  const { petId } = useParams()

  const { data, refetch } = usePetQuery({ _id: petId as string })

  const [petReaction, setPetReaction] = useState<PetMood>()
  const [reactionTimer, setReactionTimer] = useState<NodeJS.Timeout>()

  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.NONE)
  const setTab = useCallback(
    (tab: Tabs) => () => {
      if (activeTab === tab) {
        setActiveTab(Tabs.NONE)
      } else {
        setActiveTab(tab)
      }
    },
    [activeTab],
  )

  const feedPet = useCallback(async () => {
    if (!data) return

    clearTimeout(reactionTimer)
    await api.item.claimItemById({
      itemId: config.BASE_FOOD_ID(),
      itemType: ItemType.FOOD,
    })

    const response = await api.pet.feedPetById({
      _id: data._id,
      foodId: config.BASE_FOOD_ID(),
    })

    setPetReaction(response.pet.mood)
    const timeout = setTimeout(() => {
      setPetReaction(undefined)
    }, 5000)
    setReactionTimer(timeout)
    refetch()
  }, [data, reactionTimer, refetch])

  if (!data) {
    return MyPetPage.Skeleton()
  }

  const relationship = data.relationships.find((r) => r.target === data.owner)

  return (
    <div className="mx-auto max-w-lg h-screen shadow-lg flex flex-col bg-slate-100">
      <Navbar title={data.name} />
      <div className="grow">
        <div className="h-full flex flex-col justify-between">
          <PetImage name={data.name} mood={data.mood} reaction={petReaction} />
          <div className="sticky bottom-0 space-y-2">
            <div className="bg-white rounded-t-3xl p-4 space-y-2 transition-all">
              <Show>
                <Show.When isTrue={activeTab === Tabs.NONE}>
                  <PetStatesView states={data.states} />
                </Show.When>
                <Show.When isTrue={activeTab === Tabs.FEED}>
                  <Text>Feed</Text>
                </Show.When>
                <Show.When isTrue={activeTab === Tabs.STATS}>
                  <PetAttributesView
                    attributes={data.attributes}
                    relationship={relationship}
                  />
                </Show.When>
                <Show.When isTrue={activeTab === Tabs.PLAY}>
                  <Text>Play</Text>
                </Show.When>
              </Show>
              <div className="grid grid-cols-3 pt-2 gap-2">
                <Button onClick={feedPet}>Feed</Button>
                <Button onClick={setTab(Tabs.STATS)}>Stats</Button>
                <Button onClick={setTab(Tabs.PLAY)}>Play TBD</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MyPetPage.Skeleton = () => {
  return (
    <div className="mx-auto max-w-lg h-screen shadow-lg p-4 space-y-4">
      <div className="h-6 w-32 bg-gray-200 animate-pulse rounded" />
    </div>
  )
}

export default MyPetPage
