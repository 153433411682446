/* eslint-disable import/prefer-default-export */

import { tv } from '../tv'

export const textStyles = tv('text-black', {
  variants: {
    variant: {
      // Butler fonts
      headlineMD: 'text-[26px] font-semibold font-montserrat leading-[30px]',
      headlineSM: 'text-[22px] font-semibold leading-[26px]',
      labelMD: 'text-[16px] font-semibold leading-[20px]',
      labelSM: 'text-[10px] font-bold leading-[12px]',
      titleMD: 'text-[22px] font-semibold leading-[26px]',
      titleSM: 'text-[16px] font-semibold leading-[20px]',
      bodyMD: 'text-[16px] font-normal leading-[20px]',
      bodySM: 'text-[14px] font-normal leading-[20px]',
    },
  },
  defaultVariants: {
    variant: 'bodyMD',
  },
})
