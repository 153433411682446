import { useForm } from 'react-hook-form'
import api from '../../api'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'

export default function useLoginInternalUserForm() {
  const navigate = useNavigate()

  const form = useForm<{
    email: string
    password: string
  }>()

  const submit = form.handleSubmit(async (values) => {
    try {
      await api.auth.loginInternalUser(values)

      navigate(ROUTES.MY_PET_GROUP())
    } catch (error: any) {
      throw new Error(error.response.data.message)
    }
  })

  return {
    ...form,
    submit,
  }
}
