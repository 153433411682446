import { tv } from '../tv/tailwind-variant'

export const buttonStyles = tv(
  'flex items-center justify-center bg-[#448AFF] rounded-full px-4 py-2 transition-all disabled:cursor-not-allowed outline-none',
  {
    variants: {
      variant: {
        primary: '',
        // 'bg-black hover:bg-white border border-black disabled:bg-gray-400 disabled:hover:bg-gray-400 disabled:border-gray-400',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
)

export const textButtonStyles = tv('', {
  variants: {
    variant: {
      primary: 'text-white',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})
