import { createContext, useContext, useState } from 'react'
import ToastUI from '../components/ui/Toast/Toast'

const TOAST_TIMEOUT_MS = 3000

type Toast = {
  message: string
  id: number
  type: 'success' | 'error' | 'warning' | 'info'
  code?: string
}

type ToastContextType = {
  showToast: (toast: Omit<Toast, 'id'>) => void
}

const ToastContext = createContext<ToastContextType>({ showToast: () => {} })

function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toasts, setToasts] = useState<Toast[]>([])

  const showToast = (toast: Omit<Toast, 'id'>) => {
    const { message, type, code } = toast

    const id = new Date().getTime()

    setToasts((prev) => [...prev, { message, type, id, code }])

    setTimeout(() => {
      setToasts((prev) => prev.filter((t) => t.id !== id))
    }, TOAST_TIMEOUT_MS)
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          right: '0',
          zIndex: 50,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {toasts.map((toast) => (
          <ToastUI key={toast.id} variant={toast.type} code={toast.code}>
            {toast.message}
          </ToastUI>
        ))}
      </div>
      {children}
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)

export default ToastProvider
