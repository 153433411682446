/* eslint-disable @typescript-eslint/no-explicit-any */
import { Children } from 'react';

function Show({ children }: any) {
  let when: any = null;
  let otherwise: any = null;

  Children.forEach(children, (child) => {
    if (child.props.isTrue === undefined) {
      otherwise = child;
    } else if (!when && child.props.isTrue === true) {
      when = child;
    }
  });

  return when || otherwise;
}

Show.When = ({ isTrue, children }: any) => isTrue && children;
Show.Else = ({ render, children }: any) => render || children;

export default Show;
