import * as auth from './auth'
import * as pet from './pet'
import * as item from './item'

const api = {
  auth,
  pet,
  item,
}

export default api
