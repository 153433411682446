import axios from 'axios'
import { Pet } from '../entities/pet'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

export async function getPetById(data: { _id: string }) {
  try {
    const response = await axios.get<{ pet: Pet }>(
      `${REACT_APP_API_URL}/pet/get-pet/${data._id}`,
      { withCredentials: true },
    )

    return response.data
  } catch (error: any) {
    throw error
  }
}

export async function getPetGroupByOwner() {
  try {
    const response = await axios.get<{ pets: Pet[] }>(
      `${REACT_APP_API_URL}/pet/get-pet-group`,
      {
        withCredentials: true,
      },
    )

    return response.data
  } catch (error: any) {
    throw error
  }
}

export async function feedPetById(data: { _id: string; foodId: string }) {
  try {
    const response = await axios.patch<{
      pet: Pet
    }>(
      `${REACT_APP_API_URL}/pet/feed-pet/${data._id}`,
      {
        ...data,
      },
      { withCredentials: true },
    )

    return response.data
  } catch (error: any) {
    throw error
  }
}

export async function createPet(data: { name: string }) {
  try {
    const response = await axios.post<{
      pet: Pet
    }>(`${REACT_APP_API_URL}/pet/create-pet`, data, { withCredentials: true })

    return response.data
  } catch (error: any) {
    throw error
  }
}
