import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../config/query-keys'
import api from '../../api'
import { useToast } from '../../providers/ToastProvider'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'

export default function useMyPetsQuery() {
  const { showToast } = useToast()
  const navigate = useNavigate()

  const query = useQuery({
    queryKey: QUERY_KEYS.MY_PETS(),
    queryFn: async () => {
      const data = await api.pet.getPetGroupByOwner()

      if (data.pets.length === 0) {
        navigate(ROUTES.CREATE_PET())
      }

      return data.pets
    },
    throwOnError: (error: any) => {
      showToast({
        type: 'error',
        message: error.response.data.message,
        code: error.response.data.code,
      })
      throw Error(error.response.data.message)
    },
  })

  return {
    ...query,
  }
}
