import Button from '../../components/ui/Button/Button'
import Input from '../../components/ui/Input/Input'
import useRegisterInternalUserForm from '../../hooks/forms/useRegisterInternalUserForm'

function RegisterPage() {
  const { register, submit } = useRegisterInternalUserForm()

  return (
    <div className="mx-auto max-w-lg h-screen shadow-lg p-4">
      <h1>Register</h1>
      <form onSubmit={submit}>
        <Input
          type="email"
          placeholder="Email"
          {...register('email', {
            required: 'Email is required, it can be fake ;)',
          })}
        />
        <Input
          type="password"
          placeholder="Password"
          {...register('password', {
            required: 'Password is required, it can be fake ;)',
          })}
        />
        <Button type="submit">Register</Button>
      </form>
    </div>
  )
}

export default RegisterPage
