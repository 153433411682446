import { Link } from 'react-router-dom'
import Text from '../../components/ui/Text/Text'
import { Pet } from '../../entities/pet'
import useMyPetsQuery from '../../hooks/queries/useMyPetsQuery'
import { ROUTES } from '../../config/routes'

function PetCard({ pet }: { pet: Pet }) {
  return (
    <Link to={ROUTES.MY_PET_BY_ID(pet._id)}>
      <div className="w-full bg-gray-200 aspect-square rounded-3xl overflow-hidden p-4">
        <Text>{pet.name}</Text>
      </div>
    </Link>
  )
}

PetCard.Skeleton = () => {
  return (
    <div className="rounded-3xl w-full bg-gray-200 aspect-square animate-pulse" />
  )
}

function MyPetsPage() {
  const { data } = useMyPetsQuery()

  return (
    <div className="mx-auto max-w-lg h-screen shadow-lg p-4 space-y-4">
      <Text>My Pets</Text>
      <div className="grid grid-cols-2 gap-4">
        {data
          ? data.map((pet) => <PetCard key={pet._id} pet={pet} />)
          : Array.from({ length: 4 }).map((_, index) => (
              <PetCard.Skeleton key={index} />
            ))}
      </div>
    </div>
  )
}

export default MyPetsPage
