import { Link } from 'react-router-dom'
import Button from '../../components/ui/Button/Button'
import Input from '../../components/ui/Input/Input'
import useLoginInternalUserForm from '../../hooks/forms/useLoginInternalUserForm'
import { ROUTES } from '../../config/routes'

function LoginPage() {
  const { register, submit } = useLoginInternalUserForm()

  return (
    <div className="mx-auto max-w-lg h-screen shadow-lg p-4">
      <h1>Login</h1>
      <form onSubmit={submit}>
        <Input
          type="email"
          placeholder="Email"
          {...register('email', {
            required: 'Email is required, it can be fake ;)',
          })}
        />
        <Input
          type="password"
          placeholder="Password"
          {...register('password', {
            required: 'Password is required, it can be fake ;)',
          })}
        />
        <Button type="submit">Login</Button>
      </form>

      <Link to={ROUTES.REGISTER()}>
        <Button>Register</Button>
      </Link>
    </div>
  )
}

export default LoginPage
